<template>
  <v-form ref="form1">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>商品検索</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <!-- 商品コード -->
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="productCode"
                    :items="productList"
                    :loading="isLoading"
                    :search-input.sync="searchProductCode"
                    item-text="comboItem"
                    item-value="code"
                    clearable
                    @change="updateCodeCombo"
                    hint="完全一致"
                    persistent-hint
                    label="商品コード"
                    placeholder="3桁入力してください"
                    prepend-icon="mdi-database-search"
                  ></v-autocomplete>
                </v-col>
                <!-- 品番 -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="productNo"
                    label="品番"
                    :rules="[limit_length20]"
                    counter="20"
                    clearable
                    :disabled="isDisableText"
                    hint="部分一致"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
                <!-- 型番/商品名 -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="productName"
                    label="型番/商品名"
                    :rules="[limit_length20]"
                    counter="20"
                    clearable
                    :disabled="isDisableText"
                    hint="部分一致"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
                <!-- 略称名 -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="productAbbreviation"
                    label="略称名"
                    :rules="[limit_length25]"
                    counter="25"
                    clearable
                    :disabled="isDisableText"
                    hint="部分一致"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
                <!-- 商品分類コード -->
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="productClass"
                    label="商品分類コード"
                    :items="productClassList"
                    item-text="comboItem"
                    item-value="code"
                    clearable
                    :disabled="isDisableText"
                    hint="完全一致"
                    persistent-hint
                  >
                  </v-autocomplete>
                </v-col>
                <!-- メーカー -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="productMakerName"
                    label="メーカー"
                    :rules="[limit_length4]"
                    counter="4"
                    clearable
                    :disabled="isDisableText"
                    hint="部分一致"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-5"></v-divider>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <!-- 検索ボタン -->
            <v-btn color="secondary" v-on:click="onClickSearch" rounded>
              <v-icon left> mdi-magnify </v-icon>検索
            </v-btn>
            <!-- <span v-if="success">バリデーションOK！</span> -->
          </v-card-actions>
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="productItems"
              :footer-props="footerProps"
              class="elevation-5 mt-5 scroll-lock-item-search"
              item-key="productCode"
              multi-sort
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title style="white-space: normal"
                    >検索結果</v-toolbar-title
                  >
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-toolbar-title style="white-space: normal">
                    {{ productItems.length }}件
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- 新規登録ボタン -->
                  <v-btn dark color="secondary" rounded @click="insertItem()">
                    <v-icon left>mdi-pen-plus</v-icon>新規登録
                  </v-btn>
                </v-toolbar>
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <template v-if="transitionFlg == true">
                      <v-btn
                        class="m-0"
                        fab
                        dark
                        x-small
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                        @click="selectItem(item)"
                        :disabled="isDisableProduct(item.productCode)"
                      >
                        <v-icon>mdi-checkbox-marked-circle </v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="m-0"
                        fab
                        dark
                        x-small
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                        label=""
                        @click="editItem(item)"
                        :disabled="!isLoginUserAuth"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                  </template>
                  <span>{{ tooltipText }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.listPrice`]="{ item }">
                <span>
                  <div v-if="item.listPrice==null || item.listPrice==``">{{item.listPrice}}</div>
                  <div v-else>{{ new Number(item.listPrice).toLocaleString() }}</div></span>
              </template>
              <template v-slot:[`item.salesPrice`]="{ item }">
                <span>
                  <div v-if="item.salesPrice==null || item.salesPrice==``">{{item.salesPrice}}</div>
                  <div v-else>{{ new Number(item.salesPrice).toLocaleString() }}</div></span>
              </template>
              <template v-slot:[`item.supplierPrice`]="{ item }">
                <span>
                  <div v-if="item.supplierPrice==null || item.supplierPrice==``">{{item.supplierPrice}}</div>
                  <div v-else>{{ new Number(item.supplierPrice).toLocaleString() }}</div></span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import {
  executeHanbaikanriSql,
} from "@/graphql/mutations";
import { ALERT_MESSAGE_COMMON_ERROR, ALERT_SUNELE_EDIT } from "@/assets/js/dialog_messages";
import { MANAGER_KIND_LIST, CUSTOM_FOOTER_PROPS_PRODUCT_SEARCH } from '@/assets/js/const';
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "ProductSearchArea",
  props: {
    // 商品情報編集時の商品コード
    reqProductCode: Number,
    // 商品検索モーダル：モーダル表示 or 通常の画面遷移
    transitionFlg: Boolean,
    // 商品検索モーダル：入力画面の明細NO
    targetBranchNo: Number,
  },
  data: () => ({
    // Datatablesのヘッダー
    headers: [
      { text: "操作", value: "actions", sortable: false, width: "50px" },
      { text: "商品コード", value: "productCode", width: "80px" },
      { text: "品番", value: "productNo", width: "100px" },
      { text: "型番/商品名", value: "productName", width: "200px" },
      { text: "略称名", value: "productAbbreviation", width: "100px" },
      { text: "定価", value: "listPrice", width: "10px" },
      { text: "売単価", value: "salesPrice", width: "10px" },
      { text: "仕入単価", value: "supplierPrice", width: "10px" },
      { text: "商品分類コード", value: "productClass", width: "150px" },
      { text: "メーカー", value: "productMakerName", width: "80px" },
    ],
    // 商品コード
    productCode: null,
    // 品番
    productNo: null,
    // 型番/商品名
    productName: null,
    // 略称名
    productAbbreviation: null,
    // 定価
    listPrice: null,
    // 売単価
    salesPrice: null,
    // 仕入単価
    supplierPrice: null,
    // 分類名
    productClass: null,
    // メーカー
    productMakerName: null,

    // 商品コード検索用
    productList: [],
    // 商品分類コード検索用
    productClassList: [],
    // Datatablesのデータ
    productItems: [],

    // 検索用テキストボックス無効化フラグ
    isDisableText: false,

    // ログインユーザー情報
    loginUserInfo: null,

    // API検索用オートコンプリート
    isLoading: false,
    searchProductCode: null,

    managerAuthSectionList: MANAGER_KIND_LIST,
    // テーブルフッターオプション
    footerProps: CUSTOM_FOOTER_PROPS_PRODUCT_SEARCH,
    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 文字数の制約 最大4文字
    limit_length4: (value) =>
      value == null || value.length <= 4 || "4文字以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value == null || value.length <= 20 || "20文字以内で入力してください",
    // 文字数の制約 最大25文字
    limit_length25: (value) =>
      value == null || value.length <= 25 || "25文字以内で入力してください",
  }),
  /**
   * computed 算出プロパティ
   */
  computed: {
    /**
     * ログイン中ユーザーの管理者チェック
     */
    isLoginUserAuth() {
      let loginUser = this.managerAuthSectionList.find(
        (items) => items.code == this.$store.getters.user.TANTOSHA_AUTH_KBN
      );
      // リストに存在しない区分はfalse
      return loginUser ? loginUser.isAuthUser : false;
    },
    /**
     * ツールチップタイトル設定
     */
    tooltipText() {
      return this.transitionFlg === true ? "選択" : "修正";
    },
    /**
     * 廃番商品の場合選択ボタンを非表示にする
     */
    isDisableProduct() {
      return function (productCode) {
        let productDisable = false;
        // 廃番フラグ9でなければ選択ボタンをdisabledにする
        let productInfo = this.productItems.find(
          (items) => items.productCode == productCode
        );
        if (productInfo.productDiscontinued !== 9) {
          productDisable = true;
        }
        // 商品分類コードが、10000番台または40000番台から60000番台でなければ選択ボタンをdisabledにする
        let index = String(productInfo.productClass).indexOf('：');
        let productClass = index  !== -1 ? productInfo.productClass.substring(0, index) : productInfo.productClass;
        if (!(10000 <= productClass < 20000 || 40000 <= productClass < 60000)) {
          productDisable = true;
        }
        return productDisable;
      }
    },
  },
  /**
   * 特定のデータや算出プロパティの状態の変化を監視
   */
  watch: {
    async searchProductCode(val) {
      console.log("val = " + val);
      // 入力値がNULL、3文字以内、オートコンプリートの選択値（7文字以上）だった場合
      if (val == null || val.length < 3 || val.length > 7) {
        return
      }
      // ローディング中は検索しない
      if (this.isLoading) {
        return;
      }

      // オートコンプリートをローディング中にする
      this.isLoading = true
      // 商品コードのリストを初期化
      this.productList = [];
      let sqlList = [
        `SELECT SH_SHOCD, SH_SHONM, SH_SHOKNM FROM SHOHIN` +
        ` WHERE SH_SHOCD LIKE '${val}%' `+
        ` AND ((SH_SBUBCD >= 40000 AND SH_SBUBCD < 60000) OR (SH_SBUBCD >= 10000 AND SH_SBUBCD < 20000))` +
        ` AND SH_SHOKNM IS NOT NULL AND SH_HAINOKB = 9` +
        ` ORDER BY SH_SHOCD`
      ];
      let resultProductData = await this.executeHanbaikanriSql(sqlList);
      if (resultProductData == null || resultProductData == void 0) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // オートコンプリートのローディングを解除
        this.isLoading = false;
        return;
      }
      console.log("get resultProductData = " + resultProductData[0].length)
      for (const data of resultProductData[0]) {
        this.productList.push({
          comboItem: data.SH_SHOCD + "：" + data.SH_SHONM,
          code: data.SH_SHOCD,
        });
      }
      // オートコンプリートのローディング中を解除する
      this.isLoading = false;
    },
  },
  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディングの開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // 初期化処理の実行
    if (!(await this.initialize())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },

  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return this.loginUserInfo !== null;
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      //  販売管理システムの商品分類基本テーブルから商品分類コードを取得。
      this.productClassList = [];
      let sqlClassificationList = [
        "SELECT HB_SBUCD, HB_SBUNM FROM SHOBU WHERE HB_SBUCD > 40000 AND HB_SBUCD < 60000 ORDER BY HB_SBUCD",
      ];

      let resultProductClassData = await this.executeHanbaikanriSql(sqlClassificationList);
      if (resultProductClassData == null || resultProductClassData == void 0) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディング画面を解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      for (const data of resultProductClassData[0]) {
        this.productClassList.push({
          comboItem: data.HB_SBUCD + "：" + data.HB_SBUNM,
          code: data.HB_SBUCD,
        });
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },

    /**
     * 編集ボタン押下時処理
     */
    editItem(item) {
      // メソッド名を定義
      const method_name="editItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // サン電子商品（商品分類コードが10000 ~ 19999まで）は編集禁止
      if (item.productClass >= 10000 && item.productClass <= 19999) {
        alert(ALERT_SUNELE_EDIT);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 入力画面に編集対象の商品コードを渡す
      this.$router.push({
        name: "ProductInsert",
        query: { reqProductCode: item.productCode },
      });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 選択ボタン押下時処理
     */
    selectItem(item) {
      // メソッド名を定義
      const method_name="selectItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.$emit("result", {
        returnNo: this.targetBranchNo,
        returnProductCode: item.productCode,
      });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 検索ボタン押下時
     */
    async onClickSearch() {
      // メソッド名を定義
      const method_name="onClickSearch"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 検索結果格納用リストの初期化
      this.productItems = [];

      // 初期値は全件検索(昇順10000件まで)
      let sqlSearchList = [
        `SELECT SH_SHOCD, SH_SHONM, SH_SHORNM, SH_SHOKNM, SH_MAKERNAME, SH_SBUBCD, SH_JYOTNA, SH_URITNA, SH_SIRTNA, SH_HAINOKB FROM SHOHIN WHERE ROWNUM <= 10000`
      ];

      let whereOptions = "";
      // 名前検索の場合は部分一致
      if (!this.productCode) {
        // 型番/商品名が入力されている場合
        if (this.productName) {
          whereOptions += ` AND SH_SHONM LIKE '%${this.productName}%'`;
        }
        // 品番が入力されている場合
        if (this.productNo) {
          whereOptions += ` AND SH_SHOKNM LIKE '%${this.productNo}%'`;
        }
        // 略称名が入力されている場合
        if (this.productAbbreviation) {
          whereOptions +=
            `AND SH_SHORNM LIKE '%${this.productAbbreviation}%'`;
        }
        // 分類コードが選択されている場合
        if (this.productClass) {
          whereOptions +=
          ` AND SH_SBUBCD = ${this.productClass}`;
        }
        // メーカーが入力されている場合
        if (this.productMakerName) {
          whereOptions += ` AND SH_MAKERNAME LIKE '%${this.productMakerName}%'`;
        }
      } else if (this.productCode) {
        // コード検索の場合は完全一致
        whereOptions += ` AND SH_SHOCD = ${this.productCode}`;
      }
      whereOptions += ` ORDER BY SH_SHOCD`;

      sqlSearchList += whereOptions;

      let resultSearchData = await this.executeHanbaikanriSql(sqlSearchList);
      if (resultSearchData == null || resultSearchData == void 0) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディング画面を解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 検索結果格納用リストの初期化
      this.productItems = [];
      // 結果判定
      for (const data of resultSearchData[0]) {
        // 商品分類コードと商品分類名を連結する
        let productClass = this.productClassList.find(
          (item) => item.code === data.SH_SBUBCD
        );
        productClass = productClass == null ? data.SH_SBUBCD : productClass.comboItem;

        this.productItems.push({
          productCode: data.SH_SHOCD,
          productNo: data.SH_SHOKNM,
          productName: data.SH_SHONM,
          productAbbreviation: data.SH_SHORNM,
          listPrice: data.SH_JYOTNA,
          salesPrice: data.SH_URITNA,
          supplierPrice: data.SH_SIRTNA,
          productClass: productClass,
          productMakerName: data.SH_MAKERNAME,
          productDiscontinued: data.SH_HAINOKB,
        });
      }

      // ローディング画面を解除
      this.$store.commit("setLoading", false);

      if (this.productItems.length == 10000) {
        alert(`10000件を超えるデータを取得しました。\n商品コードの昇順で、10000件のみ表示します。`);
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 新規登録ボタン押下時処理
     */
    insertItem() {
      // メソッド名を定義
      const method_name="insertItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.$router.push("/product_insert");
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * コードのコンボボックス更新時処理
     */
    updateCodeCombo() {
      // メソッド名を定義
      const method_name="updateCodeCombo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // テキストボックスの無効化
      this.isDisableText = null !== this.productCode;
      // テキストボックスをクリア
      this.productNo = "";
      this.productName = "";
      this.productAbbreviation = "";
      this.productClass = "";
      this.productMakerName = "";
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 販売管理システム用関数の実行処理
     */
    async executeHanbaikanriSql(sqlList) {
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let condition = { SQLs: sqlList };
      console.log(
        "executeHanbaikanriSql：condition = " + JSON.stringify(condition)
      );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            console.log("executeHanbaikanriSql：error");
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return null;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            console.log("executeHanbaikanriSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return responseBody.data;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeHanbaikanriSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        console.log(
          "executeHanbaikanriSql : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.v-data-table >>> th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}
.v-data-table >>> td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
/* 2列目スクロール時固定 */
.v-data-table >>> th:nth-child(2) {
  position: sticky;
  left: 50px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.v-data-table >>> td:nth-child(2) {
  position: sticky;
  left: 50px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 3列目スクロール時固定 */
.v-data-table >>> th:nth-child(3) {
  position: sticky;
  left: 130px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.v-data-table >>> td:nth-child(3) {
  position: sticky;
  left: 130px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
</style>
